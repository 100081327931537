.fp-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1rem;
    padding: 1rem;
}

.fp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #ffffff1f;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 1rem #0000001f;
}

.fp-container-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #d3d3d31f;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 1rem #0000001f;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.fp-container-row:hover {
    background-color: #ffffff31;
}

.fp-track-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1rem;
    padding: 1.5rem;
    background-color: #ffffff1f;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 1rem #0000001f;
}

.fp-track-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1rem;
}

.fp-track-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    gap: 1rem;
    background-color: #ffffff1f;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
}

.fp-track-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    gap: 0.5rem;
}

.fp-track-artist {
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffffff;
}

.fp-track-title {
    font-size: 0.75rem;
    font-weight: 400;
    color: #ffffff;
}

.album-art {
    width: 5rem;
    height: 5rem;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 1rem #0000001f;
}

.fp-track-credits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 500;
}

.fp-track-composer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 1rem #0000001f;
    padding: 1rem;
}

.fp-track-composer-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    color: lightgray;
}

.fp-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    color: #ffffff;
    font-size: 0.75rem;
    background-color: #ffffff1f;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 1rem #0000001f;
    text-align: center;
}

.loading-icon {
    animation: spin 1s infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}