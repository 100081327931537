.ac-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
}

.account-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.profile-pic {
    border-radius: 100%;
    border: 2px solid #ffffff1f;
    box-shadow: 0 0 10px 0 #0000001f;
}

.name {
    font-size: 1.5rem;
    font-weight: 500;
}

.email {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 400;
    color: #eeeeee;
}

.submit {
    cursor: pointer;
    transition: all 0.3s ease 0s;
    background-color: #ffffff1f;
    margin-top: 0.5rem;
}

.submit:hover {
    background-color: #ffffff41;
}

.links {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    width: 100%;
    gap: 1rem;
}

.ac-container  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #ffffff1f;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 10px 0 #0000001f;
}