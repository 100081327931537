.console {
    color: #ffffff;
    font-family: monospace;
    font-size: 1rem;
    padding: 0rem 1rem;
}

/*  Ensure the console-input holds focus at all times */
.console-input {
    margin-left: 1rem;
    margin-right: 1rem;
    width: 100%;
    user-select: auto;
    color: lightgrey;

    font-family: monospace;
    font-size: 1rem;
}

.console-input::placeholder {
    color: grey;
}

/* change the console-input select border color to none */
.console-input:focus {
    outline: none;
}