.register-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    gap: 2rem;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
}


.register-prompt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    background-color: #ffffff1f;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 10px 0 #ffffff1f;
    gap: 0.5rem;
    max-width: 30rem;
    min-width: 18rem;
}

.rp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #ffffff1f;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    box-shadow: 0 0 1rem #0000001f;
}

.register-prompt-details-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
}

.register-prompt-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 400;
}

.prompt-logo {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 0.25rem;
    border: 1px solid #000000;
    border-radius: 2rem;
}

.register-prompt-subtitle {
    max-width: 18rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(233, 233, 233);
    transition: all 0.25s ease-in-out;
}

.register-prompt-subtitle:hover {
    color: white;
}

.register-prompt-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.5rem;
}

.register-prompt-tier {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.35rem 1rem;
    background-color: #ffffff1f;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    width: 100%;
    gap: 0.25rem;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.register-prompt-tier:hover {
    background-color: #ffffff2f;
    border: 1px solid #ffffff2f;
}

.tooltip-container  {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 0.25rem;
}

.rp-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0.5rem 1rem;
    background-color: #ffffff1f;
    border-radius: 0.5rem;
    border: 1px solid #ffffff1f;
    width: 100%;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.text-center {
    text-align: center;
}

.compare-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
}

.compare-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 400;
    color: rgb(233, 233, 233);
}

.compare-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
}

.compare-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(233, 233, 233);
    max-width: 18rem;
}