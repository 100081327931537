.App {
  user-select: none;
  width: 100%;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;

}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  color: white;
}

.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.search-bar {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff1f;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  transition: all 0.2s ease-in-out;
}

.search-bar:hover {
  background-color: #ffffff3f;
}

.search-bar input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  width: 100%;
  padding: 0.6rem;
}

.body {
  display: flex;
  width: 100%;
  flex-wrap: wrap; /* Allow items to wrap to a new line */
  justify-content: center; /* Center items horizontally */
  gap: 2rem; /* Add some space between the grid items */
  padding-top: 2rem;
}

.disabled {
  pointer-events: none;
  filter: blur(3px);
  opacity: 0.9;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  color: white;
  backdrop-filter: blur(10px);
  font-size: 0.75rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.logo {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  filter: invert(1);
  transition: all 0.2s ease-in-out;
  animation: rotate 2s linear infinite;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

@keyframes rotate {
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    50% {
        transform: rotate3d(0, 1, 0, 90deg);
    }
    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.75rem;
  gap: 0.5rem;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 0.75rem;
}

.date-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
  padding: 0rem 1rem;
  font-weight: 500;
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff1f;
    border-radius: 1rem;
    padding: 2rem;
    height: 10rem;
    width: 20rem;
}

.tool-app-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1rem;
  background-color: #ffffff1f;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  transition: all 0.2s ease-in-out;
}

.tool-app-icon:hover {
  background-color: #ffffff3f;
}