body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
  background-image: url('./assets/bgimg.webp');
    background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-blend-mode: screen;
  color: white;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  background-color: #ffffff1f;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 1rem;
  border: 1px solid #ffffff1f;
}

input::placeholder {
  color: white;
}

a {
  color: #dbdbdb;
  text-decoration: none;
  cursor: pointer;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #ffffff1f;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 0.75rem;
  border: 1px solid #ffffff1f;
  cursor: pointer;
}

/*  Customize the horizontal scrollbar */

/*  width */
::-webkit-scrollbar {
  width: 0.1rem;
}

/*  Track */
::-webkit-scrollbar-track {
  background: #0000001f;
}

/*  Handle */
::-webkit-scrollbar-thumb {
  background: #242424;
  border-radius: 0.5rem;
}

/*  Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff5f;
}

/*  Handle on active */
::-webkit-scrollbar-thumb:active {
  background: #ffffff7f;
}

/*  Handle on focus */
::-webkit-scrollbar-thumb:focus {
  background: #ffffff7f;
}

/*  Handle on hover */
::-webkit-scrollbar-thumb:horizontal:hover {
  background: #ffffff5f;
}

/*  Handle on active */
::-webkit-scrollbar-thumb:horizontal:active {
  background: #ffffff7f;
}