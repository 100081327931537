.auth-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    margin-top: 0.5rem;
}

.onHover {
    transition: all 0.5s ease-in-out;
}

.onHover:hover {
    background-color: #ffffff3f;
}