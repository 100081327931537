.mailkit {
    text-overflow: ellipsis;
}

.mailkit-loading {
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.no-background {
    background-image: none;
    transition: all 0.5s ease-in-out;
}

.mailkit-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    position: sticky;
    top: 0;
    background-color: #000000;
    z-index: 5;
}

.mailkit-campaign-menu,
.mailkit-inbox-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.mailkit-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Mobile layout */
@media (max-width: 768px) {
    .mailkit-email {
        width: auto;
        flex-direction: column;    
    }
  
    .mailkit-email-folder {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        order: 1; /* Change the order to display it below */
        padding: 0.5rem 2rem 2rem 2rem;
        gap: 0.5rem;
        transition: all 0.3s ease; /* Add transition for smooth animation */
    }

    .mailkit-email-folder.open {
        transition: all 0.3s ease;
        display: none;
    }
  
    .mailkit-email-content {
        width: auto;
        padding: 0rem 1rem 1rem 1rem;
        transition: all 0.3s ease; /* Add transition for smooth animation */
        transform: translateY(100%); /* Start hidden off-screen */
        order: 2; /* Change the order to display it above */
        display: none;
        user-select: text;
    }
  
    /* Animation when a preview-container is clicked */
    .mailkit-email-content.open {
        transform: translateY(0%);
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease; 
        user-select: text;
    }

    .close-email-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        padding: 1rem 0rem;
    }
  }
  
  /* Desktop layout */
  @media (min-width: 769px) {
    .mailkit-email {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
  
    .mailkit-email-folder {
        width: 30%;
        order: 1; 
        transform: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1rem;
        gap: 0.5rem;
    }
  
    .mailkit-email-content {
        position: sticky;
        top: 0;
        z-index: 1;
        width: 70%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 4rem;
        padding: 1rem 1rem 1rem 2rem;
        order: 2;
        user-select: text;
    }

    .close-email-content {
        display: none;
    }
  }

.close-email-content:hover {
    animation: bounce-up 0.5s ease-in-out; 
}

@keyframes bounce-up {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}


.button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffffff1f;
    border: 1px solid #ffffff1f;
    border-radius: 0.5rem;
    overflow: hidden;
}

.button-ingroup {
    background-color: #ffffff00;
    border: none;
    border-radius: 0;
}

.selected {
    background-color: #ffffff23;
}

.preview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff1f;
    border: 1px solid #ffffff1f;
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    transition: all 0.2s ease-in-out;
    text-overflow: ellipsis;
    cursor: pointer;
    gap: 0.25rem;
}

.preview-container:hover {
    background-color: #ffffff23;
}

.preview-subject {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-overflow: ellipsis;
}

.preview-sender {
    width: 100%;
    font-size: 0.75rem;
    color: #d3d3d3;
    text-overflow: ellipsis;
}

.mailkit-email-content-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    text-overflow: ellipsis;
    overflow: none;
}

.mailkit-email-content-header-subject {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    color: white;
}

.mailkit-email-content-header-sender {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 0.75rem;
    color: #d3d3d3;
}

.mailkit-email-content-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    text-overflow: ellipsis;
    overflow: none;
    margin-top: 1rem;
}